.content {
    height: calc(100% - 96px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 48px 24px;
    .logo {
        text-align: center;
    }
    .form {
        margin-top: 48px;
        & > div:not(:first-child) {
            margin-top: 20px;
        }
        .label {
            color: #767F8F;
            font-size: 14px;
        }
        .input {
            width: calc(100% - 24px);
            height: 56px;
            background-color: white;
            border-radius: 12px;
            box-shadow: 0px 3px 10px 0px #E8EFF6;
            outline: none;
            border: none;
            padding: 0 12px;
        }
        .error {
            font-size: 12px;
            color: red;
            margin-top: 12px;
        }
        .forget {
            font-size: 16px;
            color: #F18D18;
            text-decoration: underline;
        }
    }
    .buttons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .enter {
            box-shadow: 0px 3px 10px 0px #E8EFF6;
            background-color: #F18D18;
            color: white;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            outline: none;
            border: none;
        }
        .registration {
            background-color: white;
            box-shadow: 0px 3px 10px 0px #E8EFF6;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            outline: none;
            border: none;
        }
        .conf {
            width: 100%;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #F18D18;
            font-weight: 600;
            font-size: 16px;
        }
    }
    
}