    .toolbar {
        width: calc(100% - 20px);
        height: 66px;
        background-color: white;
        position: fixed;
        bottom: 0;
        padding: 10px 10px 0;
        display: flex;
        justify-content: space-between;
        .item {
            font-size: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .header{
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
    }
    .exit {
        position: absolute;
        right: 20px;
        top: 20px;
        color: #F18D18;
        font-weight: 500;
    }